import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "challenge"
    }}>{`Challenge`}</h1>
    <p>{`Ever seen Christmas stockings? They get stuffed with all sorts of stuff. Build
a donut chart of what's what and add a mouse hover effect that shows what a
slice represents.`}</p>
    <p><a parentName="p" {...{
        "href": "https://reactviz.holiday/datasets/statistic_id946574_gifts-likely-to-be-put-in-childs-christmas-stocking-in-the-us-in-2018.xlsx"
      }}>{`Dataset`}</a></p>
    <h1 {...{
      "id": "my-solution"
    }}>{`My Solution`}</h1>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/8IOHiwI74Fc" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <iframe src="https://codesandbox.io/embed/7mlxwrjw4x?fontsize=14" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>
    <p>{`Tooltips ... tooltips are hard. So simple in theory yet organizing it into
sensible code will wreck your mind. 🤯`}</p>
    <p>{`Your goal is to build this:`}</p>
    <ol>
      <li parentName="ol">{`A tooltip component`}</li>
      <li parentName="ol">{`Some way to store tooltip position and content`}</li>
      <li parentName="ol">{`Ability to change that on mouse over`}</li>
    </ol>
    <p>{`Mousing over a thing - slice of the donut chart in this case - updates
positioning and content. This triggers a tooltip re-render. Tooltip shows up
where you need saying what you want.`}</p>
    <p>{`So how do you organize that in a way that makes sense?`}</p>
    <p>{`🤔`}</p>
    <p>{`You can watch me flounder around trying different solutions in the stream
above. In the end we went with a combination of state in the `}<inlineCode parentName="p">{`App`}</inlineCode>{` component
and React Context shared between everyone else.`}</p>
    <p>{`We're using React hooks because hooks are the hot new kid on the block and
learning new coding paradigms is fun.`}</p>
    <h2 {...{
      "id": "managing-and-sharing-tooltip-state"
    }}>{`Managing and sharing tooltip state`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const [tooltip, setTooltip] = useState({
  show: false,
  x: 0,
  y: 0,
  content: '',
  orientLeft: false,
});
`}</code></pre>
    <p>{`Our `}<inlineCode parentName="p">{`tooltip`}</inlineCode>{` state holds a `}<inlineCode parentName="p">{`show`}</inlineCode>{` flag, tooltip coordinates, and `}<inlineCode parentName="p">{`content`}</inlineCode>{`.
`}<inlineCode parentName="p">{`orientLeft`}</inlineCode>{` is the nascent beginnings of a fuller tooltip API. The tooltip
component is going to consume this context and use it to render itself.`}</p>
    <p>{`To make changing this state easier, we sneakily include `}<inlineCode parentName="p">{`setTooltip`}</inlineCode>{` in the
object passed into React Context itself.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<TooltipContext.Provider value={{ ...tooltip, setTooltip }}>
`}</code></pre>
    <p>{`Now any consumer can change values in context. Whoa`}</p>
    <p><img parentName="p" {...{
        "src": "https://media.giphy.com/media/SDogLD4FOZMM8/giphy.gif",
        "alt": null
      }}></img></p>
    <h2 {...{
      "id": "the-tooltip-component"
    }}>{`The `}{`<`}{`Tooltip> component`}</h2>
    <p>{`Our `}<inlineCode parentName="p">{`<Tooltip>`}</inlineCode>{` component doesn't do much on its own. It's a wrapper that
handles positioning, visibility, and supports a nascent orientation API. We can
use `}<inlineCode parentName="p">{`orientLeft`}</inlineCode>{` to align our tooltip left or right. A fuller API would also
have top/bottom and a bunch of similar features.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const Tooltip = ({ width, height, children }) => {
  const { x, y, show, orientLeft } = useContext(TooltipContext);

  return (
    <g
      transform={\`translate(\${orientLeft ? x - width : x}, \${y})\`}
      style={{ visibility: show ? 'visible' : 'hidden' }}
    >
      <foreignObject width={width} height={height}>
        {children}
      </foreignObject>
    </g>
  );
};
`}</code></pre>
    <p><inlineCode parentName="p">{`useContext`}</inlineCode>{` takes the `}<inlineCode parentName="p">{`TooltipContext`}</inlineCode>{` object and returns its current value on
every render. We use destructuring to get at the parts we need: coordinates,
show flag, orientation.`}</p>
    <p>{`Tooltip then renders a `}<inlineCode parentName="p">{`<g>`}</inlineCode>{` grouping element with positioning based on the
orientation, and visibility based on the flag. Inside it wraps children in a
sized `}<inlineCode parentName="p">{`foreignObject`}</inlineCode>{` element. This allows us to embed HTML inside SVG.`}</p>
    <p>{`HTML is better for tooltip content than SVG because HTML supports text
automatic layouting. Set a width and the browser will figure out what to do
with long strings. Don't get that with SVG.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`Tooltip.js`}</inlineCode>{` file also exports a React Context.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const TooltipContext = React.createContext({
  show: false,
  x: 0,
  y: 0,
  orientLeft: false,
  content: '',
});

// ...
export default Tooltip;
export { TooltipContext };
`}</code></pre>
    <p>{`Makes it easier to share the same context between different consumers.`}</p>
    <h2 {...{
      "id": "render-tooltip-in-app"
    }}>{`Render Tooltip in App`}</h2>
    <p>{`Rendering our tooltip happens in the main App component. It also holds tooltip
state that gets passed into React Context.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Tooltip, { TooltipContext } from "./Tooltip";

// ...

function App() {
  const [tooltip, setTooltip] = useState({
    show: false,
    x: 0,
    y: 0,
    content: "",
    orientLeft: false
  });

  return (
      <TooltipContext.Provider value={{ ...tooltip, setTooltip }}>
        <svg width="800" height="600">
          {* // where you put tooltip triggerers *}

          <Tooltip width={150} height={60}>
            <TooltipP>{tooltip.content}</TooltipP>
          </Tooltip>
        </svg>
      </TooltipContext.Provider>
  );
}
`}</code></pre>
    <p>{`We import tooltip and its context, then `}<inlineCode parentName="p">{`useState`}</inlineCode>{` to create a local `}<inlineCode parentName="p">{`tooltip`}</inlineCode>{`
state and its setter. Pass both of those in a common object into a
`}<inlineCode parentName="p">{`<TooltipContext.Provider`}</inlineCode>{`.`}</p>
    <p>{`That part took me a while to figure out. Yes with React hooks you still need to
render Providers. Hooks are consumer side.`}</p>
    <p>{`Render our Tooltip as a sibling to all the other SVG stuff. Any components that
want to render a tooltip will share the same one. That's how it usually works.`}</p>
    <p><inlineCode parentName="p">{`<TooltipP>`}</inlineCode>{` is a styled component by the way.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const TooltipP = styled.p\`
  background: \${chroma('green')
    .brighten()
    .hex()};
  border-radius: 3px;
  padding: 1em;
\`;
`}</code></pre>
    <p>{`Nice green background, rounded corners, and a bit of padding.`}</p>
    <p><img parentName="p" {...{
        "src": "https://github.com/Swizec/datavizAdvent/raw/master/src/content/gift-stockings/tooltip-closeup.png",
        "alt": "I am no designer"
      }}></img></p>
    <p>{`I am no designer 😅`}</p>
    <h2 {...{
      "id": "trigger-tooltips-from-donuts"
    }}>{`Trigger tooltips from donuts`}</h2>
    <p>{`Donut code itself is based on code we built for the
`}<a parentName="p" {...{
        "href": "https://reactviz.holiday/buy-a-tree/"
      }}>{`Will you buy a Christmas tree?`}</a>{` donut
chart.`}</p>
    <p>{`We split it into the main donut component and a component for each slice, or
`}<inlineCode parentName="p">{`<Arc>`}</inlineCode>{`. Makes it easier to calculate coordinates for tooltips. Means we ca
handle slice highlighted state locally in its own component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const Arc = ({ d, r, color, offsetX, offsetY }) => {
  const [selected, setSelected] = useState(false);
  const tooltipContext = useContext(TooltipContext);

  const arc = d3
    .arc()
    .outerRadius(selected ? r + 10 : r)
    .innerRadius(selected ? r - 80 : r - 75)
    .padAngle(0.01);

  const mouseOver = () => {
    const [x, y] = arc.centroid(d);

    setSelected(true);
    tooltipContext.setTooltip({
      show: d.index !== null,
      x: x + offsetX + 30,
      y: y + offsetY + 30,
      content: d.data.stuffer,
      orientLeft: offsetX < 0,
    });
  };

  const mouseOut = () => {
    setSelected(null);
    tooltipContext.setTooltip({ show: false });
  };

  return (
    <path
      d={arc(d)}
      fill={color}
      onMouseOver={mouseOver}
      onMouseOut={mouseOut}
      style={{ cursor: 'pointer' }}
    />
  );
};
`}</code></pre>
    <p>{`Here you can see a downside of hooks: They can lead to pretty sizeable
functions if you aren't careful.`}</p>
    <p>{`We create a `}<inlineCode parentName="p">{`selected`}</inlineCode>{` flag and its setter with a `}<inlineCode parentName="p">{`useState`}</inlineCode>{` hook and we hook
into our tooltip context with `}<inlineCode parentName="p">{`useContext`}</inlineCode>{`. We'll be able to use that
`}<inlineCode parentName="p">{`setTooltip`}</inlineCode>{` method we added to show a tooltip.`}</p>
    <p>{`Then we've got that `}<inlineCode parentName="p">{`const arc`}</inlineCode>{` stuff. It creates an arc path shape generator.
Radius depends on `}<inlineCode parentName="p">{`selected`}</inlineCode>{` status.`}</p>
    <p>{`All that is followed by our mouse eve handling fucntions.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const mouseOver = () => {
  const [x, y] = arc.centroid(d);

  setSelected(true);
  tooltipContext.setTooltip({
    show: d.index !== null,
    x: x + offsetX + 30,
    y: y + offsetY + 30,
    content: d.data.stuffer,
    orientLeft: offsetX < 0,
  });
};
`}</code></pre>
    <p><inlineCode parentName="p">{`mouseOver`}</inlineCode>{` is the active function. Mouse over an arc and it calculates its
center, sets the arc to `}<inlineCode parentName="p">{`selected`}</inlineCode>{`, and pushes necessary info into tooltip
state. This triggers a re-render of the tooltip component and makes it show up.`}</p>
    <p>{`Technically it triggers a re-render of our whole app because it's tied to `}<inlineCode parentName="p">{`App`}</inlineCode>{`
state. You could split that out in a bigger app. Or rely on React being smart
enough to figure out the smallest possible re-render.`}</p>
    <p>{`Deselecting the arc happens in a `}<inlineCode parentName="p">{`mouseOut`}</inlineCode>{` function`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const mouseOut = () => {
  setSelected(false);
  tooltipContext.setTooltip({ show: false });
};
`}</code></pre>
    <p>{`Set `}<inlineCode parentName="p">{`selected`}</inlineCode>{` to falls and hide the tooltip.`}</p>
    <p>{`With all that defined, rendering our arc is a matter of returning a path with
some attributes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`return (
  <path
    d={arc(d)}
    fill={color}
    onMouseOver={mouseOver}
    onMouseOut={mouseOut}
    style={{ cursor: 'pointer' }}
  />
);
`}</code></pre>
    <p>{`Use the arc generator to create the shape, fill it with color, set up mouse
events, add a dash of styling.`}</p>
    <h3 {...{
      "id": "render-a-donut-"
    }}>{`Render a donut 🍩`}</h3>
    <p>{`We did all the complicated state and tooltip stuff in individual arcs. The
donut component uses a `}<inlineCode parentName="p">{`pie`}</inlineCode>{` generator and renders them in a loop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const StockingDonut = ({ data, x, y, r }) => {
  const pie = d3.pie().value(d => d.percentage);

  const color = chroma.brewer.set3;
  return (
    <g transform={\`translate(\${x}, \${y})\`}>
      {pie(data).map(d => (
        <Arc
          d={d}
          color={color[d.index]}
          r={r}
          key={d.index}
          offsetX={x}
          offsetY={y}
        />
      ))}
    </g>
  );
};
`}</code></pre>
    <p><inlineCode parentName="p">{`d3.pie`}</inlineCode>{` takes our data and returns all the info you need to build a donut.
Start angles, end angles, stuff like that.`}</p>
    <p>{`Render a grouping element that centers our donut on `}<inlineCode parentName="p">{`(x, y)`}</inlineCode>{` coordiantes,
render `}<inlineCode parentName="p">{`<Arc>`}</inlineCode>{`s in a loop.`}</p>
    <p>{`Make sure to pass offsetX and offsetY into each arc. Arcs are positioned
relatively to our donut center, which means they don't know their absolute
position to pass into the tooltip context. Offsets help with that.`}</p>
    <h2 {...{
      "id": "️"
    }}>{`✌️`}</h2>
    <p>{`And that's how you make tooltips in SVG with React hooks. Same concepts and
complications apply if you're using normal React state or even Redux or
something.`}</p>
    <p>{`You need a global way to store info about the tooltip and some way to trigger
it from sibling components.`}</p>
    <p><img parentName="p" {...{
        "src": "https://github.com/Swizec/datavizAdvent/raw/master/src/content/gift-stockings/christmas-stockings.png",
        "alt": null
      }}></img></p>
    <h2 {...{
      "id": "ps-a-neat-way-to-usedata"
    }}>{`PS: A neat way to useData`}</h2>
    <blockquote className="twitter-tweet" data-lang="en"><p lang="en" dir="ltr">Yeah I&#39;ve been doing that pattern a lot.<br /><br />const [state, setState] = useState(null)<br />useEffect(() =&gt; doStuff().then(setState), [!state])</p>&mdash; Swizec Teller (@Swizec) <a href="https://twitter.com/Swizec/status/1071461874170945536?ref_src=twsrc%5Etfw">December 8, 2018</a></blockquote>
    <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
    <p>{`Got tired of the `}<inlineCode parentName="p">{`useState`}</inlineCode>{`/`}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` dance when loading data with hooks.
Built a new hook called `}<inlineCode parentName="p">{`useData`}</inlineCode>{`. That's a neat feature of hooks; you can make
new ones.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function useData(loadData) {
  const [data, setData] = useState(null);

  useEffect(() => {
    loadData(setData);
  }, [!data]);

  return data;
}
`}</code></pre>
    <p>{`Takes a loadData function, sets up `}<inlineCode parentName="p">{`useState`}</inlineCode>{` for the data, uses an effect to
load it, gives you `}<inlineCode parentName="p">{`setData`}</inlineCode>{` so you can return the value, and returns the final
value to your component.`}</p>
    <p>{`You use it like this 👇`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function App() {
  const data = useData(setData =>
    d3
      .tsv("/data.tsv", d => ({
        stuffer: d.stuffer,
        percentage: Number(d.percentage)
      }))
      .then(setData)
  );
`}</code></pre>
    <p>{`Much cleaner I think 👌`}</p>
    <p>{`Might be cleaner to take a promise and handle `}<inlineCode parentName="p">{`setData`}</inlineCode>{` internally. Hmm ... 🤔`}</p>
    <p>{`Thinking I might open source this, but it needs a few more iterations.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      